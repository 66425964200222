export const getFilePreview = (file) =>
  new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader()

      reader.onload = function (e) {
        resolve(e.target.result)
      }

      reader.onerror = function (e) {
        reject(e)
      }

      reader.readAsDataURL(file) // convert to base64 string
    } else {
      reject('File is missing')
    }
  })
