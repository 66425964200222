import { useCallback, useState } from 'react'
import { set } from 'lodash'

export default function useValues(defaultValues = {}) {
  const [key, setKey] = useState(Date.now())
  const [values, setValues] = useState(defaultValues)

  /* Useful in form inputs: onChange={handleChange('keyName')} */
  const handleChange = useCallback(
    (key) => (value) => {
      setValues((state) => set({ ...state }, key, value))
    },
    [values]
  )

  const reset = () => {
    setValues(defaultValues)
    setKey(Date.now())
  }

  /* Update a single value or an object or values */
  const updateValues = useCallback(
    (keyOrObject, value) => {
      if (typeof keyOrObject === 'string') {
        handleChange(keyOrObject)(value)
      } else if (typeof keyOrObject === 'object') {
        setValues((state) => ({ ...state, ...keyOrObject }))
      }
    },
    [values]
  )

  return { values, key, handleChange, updateValues, reset }
}
