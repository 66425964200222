import { useRef } from 'react'
import useService from '../../../../../../services/hooks/useService'
import FileService from '../../../../../../services/FileService'
import useRequest from '../../../../../../services/hooks/useRequest'
import { useAuth } from '../../../../../../providers/authentication/context'
import useError from '../../../../../../services/hooks/useError'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import { handleSuccess } from '../../../../../../utils/alerts'
import { mutate } from 'swr'

export default function useSubmit({ onSuccess, originalFile }) {
  const { user } = useAuth()
  const fileService = useService(FileService)
  const authenticationService = useService(AuthenticationService)
  const { execWithCallback: execFile, loading, error: errorFile } = useRequest(fileService.createFileByUserUUID)
  const { exec: execUser, error: errorUser } = useRequest(authenticationService.updateUserByUUID)

  useError(errorFile, 'files')
  useError(errorUser, 'auth')

  const editorRef = useRef(null)

  const updateData = async (blob) => {
    //console.log(originalFile)
    const file = new File([blob], originalFile.Temp.name, {
      type: originalFile.Temp.type,
    })
    execFile(
      user.UUID,
      file
    )(async (file) => {
      await execUser(user.UUID, { ...user, Photo: file })
      onSuccess && onSuccess()
      await mutate([user.UUID, '/users/[uuid]?roles'])
      handleSuccess('Avatar actualizado con éxito.')
    })
  }

  const handleSubmit = () => {
    const image = editorRef.current?.getImage()
    if (image) {
      image.toBlob(updateData)
    }
  }

  return { editorRef, handleSubmit, loading }
}
