import { handleError } from './alerts'
import { getFilePreview } from './files'

export const parseFormatAndCheckImage = async (file, maxSize) => {
  if (['image/png', 'image/jpeg'].indexOf(file['type']) === -1) {
    handleError('internal: file is not image', 'files')
    return
  }

  if (file['size'] / 1024 / 1024 > maxSize) {
    handleError('internal: file size greater than 10MB', 'files')
    return
  }

  const preview = await getFilePreview(file)

  return { Temp: file, URL: preview }
}

export const parseFormatAndCheckFile = async (file, maxSize = 8) => {
  if (file['type'] !== 'application/pdf') {
    handleError('internal: file is not pdf', 'files')
    return
  }

  if (file['size'] / 1024 / 1024 > maxSize) {
    if (maxSize === 8) {
      handleError('internal: file size greater than 8MB', 'files')
    } else {
      handleError('internal: file is not video', 'files')
    }
    return
  }

  return { Temp: file }
}

export const parseFormatAndCheckVideo = async (file, maxSize, checkMaxSize) => {
  if (file['type'] !== 'video/mp4') {
    handleError('internal: file is not video', 'files')
    return
  }

  if (checkMaxSize && file['size'] / 1024 / 1024 > maxSize) {
    handleError('internal: file size greater than expected', 'files')
    return
  }

  return { Temp: file }
}
