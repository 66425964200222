/**
 * Add leading zeros to a number.
 * @param {string | number} num
 * @param {number} size
 * @returns {string}
 */
export const pad = (num, size) => {
  num = num.toString()
  while (num.length < size) num = '0' + num
  return num
}
