import { useMemo } from 'react'
import timezones from '../data/timezones'

/**
 * @namespace TimezoneOption
 * @param {String} value
 * @param {String} label
 */

/**
 * @returns {{options: TimezoneOption[], defaultTimezone: String}}
 */
export default function useTimezones() {
  const options = useMemo(() => {
    return timezones.map((timezone) => ({ key: timezone, value: timezone, label: timezone }))
  }, [])

  const defaultTimezone = useMemo(() => {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Santiago'
    } catch {
      return ''
    }
  }, [])

  return { options, defaultTimezone }
}
