import { useCallback, useRef, useState } from 'react'
import { parseFormatAndCheckImage } from '../utils/forms'

export default function useImageInput({ onChange, maxSize = 10 }) {
  const [preview, setPreview] = useState(null)
  const ref = useRef(null)

  const handleChange = useCallback(
    async (e) => {
      if (e.target.files?.length > 0) {
        const file = e.target.files[0]
        const value = await parseFormatAndCheckImage(file, maxSize)

        if (value) {
          setPreview(value.URL)
          onChange && onChange(value)
        }
      }
    },
    [onChange]
  )

  const handleOpen = useCallback(() => {
    if (ref?.current) {
      ref.current.click()
    }
  }, [])

  return { ref, preview, handleOpen, handleChange }
}
