import Service from './Service'

export default class FileService extends Service {
  createFileByUserUUID = async (userUUID, file) => {
    const formData = new FormData()
    formData.append('ResourceUUID', userUUID)
    formData.append('File', file)
    return await this._post('/files', formData)
  }

  getUploadURL = async (userUUID) => {
    return await this._post(`/files/video`, { ResourceUUID: userUUID })
  }

  getMuxVideoCheckData = async (uploadID, userUUID) => {
    return await this._post(`/files/video-check`, { UploadID: uploadID, ResourceUUID: userUUID })
  }
}
