import useValues from '../../../../../../hooks/useValues'
import useImageInput from '../../../../../../hooks/useImageInput'
import { useEffect } from 'react'

export default function useModalForm() {
  const { values, handleChange: handleValuesChange, updateValues, reset } = useValues({ zoom: 1.4 })
  const { ref, preview, handleOpen, handleChange } = useImageInput({
    onChange: handleValuesChange('file'),
  })

  useEffect(() => {
    if (values.file) {
      updateValues('showModal', true)
    }
  }, [values.file])

  useEffect(() => {
    if (values.showModal === false) {
      reset()
    }
  }, [values.showModal])

  const closeModal = () => {
    updateValues('showModal', false)
  }

  return { inputRef: ref, preview, handleChange, handleOpen, closeModal, updateValues, values, reset }
}
